import { Button, Card, CardBody, CardHeader, Col, Label, Row, UncontrolledTooltip } from "reactstrap";
import { H3 } from "../../../../AbstractElements";
import classnames from "classnames";
import classNames from 'classnames';
import SVG from "../../../../utils/CommonSvgIcon/SVG";
import CardHeaderDropDown from "../../../../Common/CardHeaderDropDown";
import ReactApexChart from "react-apexcharts";
// import { optionsVisitor } from "../Filecount/GeneralCharts";
// import Select from "react-select/dist/declarations/src/Select";
import { useEffect, useState } from "react";
import { getDaysInMonth } from 'date-fns';
import { AesDecrypt, AesEncrypt } from "../../../../Service/crypto";
import webservice from "../../../../Service/webservice";
import websocket_webservice from "../../../../Service/websocket_webservice";
import Select, { SingleValue } from "react-select";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { string } from "yup";
import { ChevronsLeft, ChevronsRight } from "react-feather";
import { ApexOptions } from 'apexcharts';
import {getcountresived} from '../store/index';
import { Link } from "react-router-dom";
import { log } from "console";

interface IClientOption {
    label: string;
    value: string;
}

interface File {
    uploaded_date?: string;
    ocr_completed_date?: string;
    Completed_date?: string;
    approved_date?: string;
    review_date?: string;
    Error_date?: string;
    Data_Fetched?: string;
    data_extraction?: string;
    client_id?: string;
    client_name?: string;
    id?: string;
    ocr_status?: string;
    xml_conversion  ?: string;
    uploaded_status?: string;
}

interface CompanyOption {
    label: string;
    value: string;
}
interface SelectedDate {
    value: number;
}

type OptionType = { label: number; value: number; };
type MonthOption = { label: string; value: number; };

const Visitors = () => {
    const { control } = useForm();
    const dispatch = useDispatch();
    const currentDate = new Date();
    
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [clientOptions, setClientOptions] = useState<IClientOption[]>([]);
    const [selectedClient, setSelectedClient] = useState<string>('All');
    const [selectedClientId, setSelectedClientId] = useState<string>('ALL');
    const [completedFilesCount, setCompletedFilesCount] = useState<number[]>([]);
    const [totalFilesCount, setTotalFilesCount] = useState<number[]>([]);
    const [inprogressFilesCount, setinprogressdata] = useState<number[]>([]);
    const [errorFilesCount, seterrorCount] = useState<number[]>([]);
    const [daysInMonth, setDaysInMonth] = useState<number[]>([]); // Initialize with the maximum number of days
    const [currentWeekStart, setCurrentWeekStart] = useState(new Date());
    const [isCurrentWeek, setIsCurrentWeek] = useState(false); // State to track if it's the current week
    const [xAxisLabels, setXAxisLabels] = useState<string[]>([]);

    const currentMonth = {
        label: currentDate.toLocaleString('default', { month: 'long' }),
        value: currentDate.getMonth() + 1, // Adding 1 to get the month index starting from 1
    };
    const currentYear = {
        label: currentDate.getFullYear(),
        value: currentDate.getFullYear(),
    };
    const [selectedYear, setSelectedYear] = useState<OptionType | null>(currentYear);
    const [selectedMonth, setSelectedMonth] = useState<MonthOption | null>(currentMonth);

    const ViewAuditLog = async () => {
        const Username = localStorage.UserName;
        const Userid = localStorage.id;
        const Clientname = selectedClient;
        const Chartname = 'File Count'
        const Data = { Username, Clientname, Userid, Chartname };

        const EncParams = AesEncrypt(JSON.stringify(Data));
        const response = await fetch(webservice + 'api/Dashboard/auditlog', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        }).then((response) => response.json());
        return response;
    };
    
    const getMonthOptions = () => {
        const months = Array.from({ length: 12 }, (_, index) => ({
            label: new Date(0, index).toLocaleString('default', { month: 'long' }),
            value: index + 1,
        }));
        return months;
    };
    const monthOptions = getMonthOptions();

    const getYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 10 }, (_, index) => ({
            label: currentYear - index,
            value: currentYear - index,
        }));
        return years;
    };
    const yearOptions = getYearOptions();

    const [currentStartDate, setCurrentStartDate] = useState(new Date());



    const UserType = localStorage.UserType;
    const Id = localStorage.VendorId;

    const get_companyname = async () => {
        // Prepare the request body based on UserType
        let bodyData = {};
        if (UserType === 'MOS') {
            bodyData = { UserType }; // Send UserType only
        } else if (UserType === 'Vendor') {
            bodyData = { UserType, VendorId: Id }; // Send both UserType and VendorId
        }
        
        // Make the fetch request
        const response = await fetch(webservice + 'api/Dashboard/getclient', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(bodyData), // Send the request body
        }).then((response) => response.json());

        const TempData = AesDecrypt(response);
        return TempData;
    };


    useEffect(() => {
        const fetchRoleId = async () => {
            try {
                const response = await get_companyname();
                const companyData = response.map((data: any) => ({
                    label: data.CompanyName,
                    value: data.id,
                }));

                // Sort the data alphabetically by label
                const sortedCompanyData = companyData.slice().sort((a: CompanyOption, b: CompanyOption) => a.label.localeCompare(b.label));
                // Add the 'All' option at the beginning of the list
                const allOption = { label: 'All', value: 'ALL' };
                const companyDataWithAll = [allOption, ...sortedCompanyData];

                // Set the client options state
                setClientOptions(companyDataWithAll);
            } catch (error) {
                console.error(error);
            }
        };

        // Fetch the initial data
        fetchRoleId();
        // WebSocket setup
        const socket = new WebSocket(`${websocket_webservice}ws/ClientDropdown/`);

        // socket.onopen = () => console.log('WebSocket connected');
        // socket.onclose = () => console.log('WebSocket closed');
        // socket.onerror = (error) => console.error('WebSocket error:', error);

        socket.onmessage = () => {
            // const change = JSON.parse(event.data);
            // console.log('WebSocket message received:', change);

            // Optionally, re-fetch the data to update the state when a WebSocket message is received
            fetchRoleId();
        };

        // Clean up the WebSocket connection when the component unmounts
        return () => {
            if (socket.readyState === 1) { // <-- This is important
                socket.close();
            }
        }
    }, []);

    // Updated updateWeek function to also update xAxisLabels
    const updateWeek = (direction: 'next' | 'prev') => {
        const newWeekStart = new Date(currentWeekStart);

        if (direction === 'next') {
            newWeekStart.setDate(newWeekStart.getDate() + 7);
        } else if (direction === 'prev') {
            newWeekStart.setDate(newWeekStart.getDate() - 7);
        }

        // Ensure selectedMonth and selectedYear are not null
        if (selectedMonth && selectedYear) {
            const currentDate = new Date();
            const daysInMonth = getDaysInMonth(new Date(selectedYear.value, selectedMonth.value - 1));

            // Check if the updated week is still in the selected month
            if (
                newWeekStart.getMonth() === selectedMonth.value - 1 &&
                newWeekStart.getFullYear() === selectedYear.value
            ) {
                setCurrentWeekStart(newWeekStart);

                // Set new x-axis labels in 'day/month/year' format
                setXAxisLabels(Array.from({ length: 7 }, (_, index) => {
                    const date = new Date(newWeekStart);
                    date.setDate(newWeekStart.getDate() + index);

                    const day = date.toLocaleDateString('default', { day: 'numeric' });
                    const month = (date.getMonth() + 1).toString().padStart(2, '0');
                    const year = date.getFullYear();
                    return `${day}/${month}/${year}`;
                }));

                // Check if the new week includes the current date to enable or disable the NextWeek button
                const newWeekEnd = new Date(newWeekStart);
                newWeekEnd.setDate(newWeekStart.getDate() + 6);

                if (currentDate >= newWeekStart && currentDate <= newWeekEnd) {
                    setIsCurrentWeek(true);
                } else {
                    setIsCurrentWeek(false);
                }
            } else {
                // Adjust to the first day of the selected month
                const startOfMonth = new Date(selectedYear.value, selectedMonth.value - 1, 1);
                setCurrentWeekStart(startOfMonth);

                // Set x-axis labels for the first week of the new month
                setXAxisLabels(Array.from({ length: 7 }, (_, index) => {
                    const date = new Date(startOfMonth);
                    date.setDate(startOfMonth.getDate() + index);

                    const day = date.toLocaleDateString('default', { day: 'numeric' });
                    const month = (date.getMonth() + 1).toString().padStart(2, '0');
                    const year = date.getFullYear();
                    return `${day}/${month}/${year}`;
                }));

                setIsCurrentWeek(false); // Disable NextWeek since it's not the current week
            }
        } else {
            // Handle the case when selectedMonth or selectedYear is null (e.g., reset to default values)
            setCurrentWeekStart(new Date());
        }
    };


    // Assuming getDaysInMonth returns the total number of days
    useEffect(() => {
        if (selectedMonth && selectedYear) {
            const totalDaysInMonth = getDaysInMonth(new Date(selectedYear.value, selectedMonth.value - 1));
            setDaysInMonth(Array.from({ length: totalDaysInMonth }, (_, index) => index + 1)); // Create array [1, 2, ..., totalDaysInMonth]

            // Update the current week start to the beginning of the selected month
            setCurrentWeekStart(new Date(selectedYear.value, selectedMonth.value - 1, 1));
        }
    }, [selectedMonth, selectedYear]);

    useEffect(() => {
        if (selectedYear && selectedMonth && daysInMonth.length > 0) {
            setXAxisLabels(Array.from({ length: daysInMonth.length }, (_, index) => {
                const currentDate = new Date(selectedYear.value, selectedMonth.value - 1, index + 1);
                return currentDate.toLocaleDateString('default', { day: 'numeric' });
            }));
        }
    }, [daysInMonth, selectedMonth, selectedYear]);


    useEffect(() => {
        const currentDate = new Date();

        if (selectedYear && selectedMonth) {
            const daysInMonth = getDaysInMonth(new Date(selectedYear.value, selectedMonth.value - 1));
            const lastDayOfMonth = new Date(selectedYear.value, selectedMonth.value - 1, daysInMonth);
            setIsCurrentWeek(true);

            // Check if the current date is in the selected month and year
            if (
                currentDate.getMonth() === selectedMonth.value - 1 &&
                currentDate.getFullYear() === selectedYear.value
            ) {
                // Calculate the remaining days in the month from the current date
                const remainingDays = daysInMonth - currentDate.getDate() + 1;
                // Set the current week start to the current date minus 6 days
                const currentWeekStartDate = new Date(currentDate);
                currentWeekStartDate.setDate(currentDate.getDate() - 6);
                setCurrentWeekStart(currentWeekStartDate);
                setXAxisLabels(Array.from({ length: 7 }, (_, index) => {
                    const date = new Date(currentWeekStartDate);
                    date.setDate(date.getDate() + index);
                    return date.toLocaleDateString('default', { day: 'numeric' });
                }));
            } else {
                // If the current date is not in the selected month, just show the default 7 days
                setCurrentWeekStart(lastDayOfMonth);
                setXAxisLabels(Array.from({ length: 7 }, (_, index) => {
                    const date = new Date(lastDayOfMonth);
                    date.setDate(lastDayOfMonth.getDate() - (6 - index));
                    return date.toLocaleDateString('default', { day: 'numeric' });
                }));
            }
        }
    }, [selectedMonth, selectedYear]);


 useEffect(() => {
    if (currentWeekStart) {
        setXAxisLabels(Array.from({ length: 7 }, (_, index) => {
            const date = new Date(currentWeekStart);
            date.setDate(currentWeekStart.getDate() + index);

            const day = date.toLocaleDateString('default', { day: 'numeric' });
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }));
    }
}, [currentWeekStart]);

    const maxCount = Math.max(
        ...inprogressFilesCount,
        ...completedFilesCount,
        ...errorFilesCount,
        ...totalFilesCount
    );

    const monthlyHistoryChart: ApexOptions = {
        series: [
            {
                name: "Received",
                data: totalFilesCount.length ? totalFilesCount : [0, 0, 0, 0, 0, 0, 0], // default values if empty
            },
            {
                name: "Completed",
                data: completedFilesCount.length ? completedFilesCount : [0, 0, 0, 0, 0, 0, 0], // default values if empty
            },
            {
                name: "In Progress",
                data: inprogressFilesCount.length ? inprogressFilesCount : [0, 0, 0, 0, 0, 0, 0], // default values if empty
            },
         
            {
                name: "Error",
                data: errorFilesCount.length ? errorFilesCount : [0, 0, 0, 0, 0, 0, 0], // default values if empty
            },
            
        ],
        chart: {
            type: "bar",
            height: 380,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "30%",
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 1,
            colors: ["transparent"],
            curve: "smooth",
            lineCap: "butt",
        },
        xaxis: {
            categories: xAxisLabels, // Use the state variable that updates with the week change
            tickAmount: 4,
            tickPlacement: "between",
            labels: {
                style: {
                    fontFamily: "Nunito, sans-serif",
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            min: 0,
            max: maxCount,
            labels: {
                formatter: function (value) {
                    return Math.round(value).toString(); // Convert the rounded value to a string
                },
            },
            title: {
                style: {
                    fontSize: "14px",
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: 500,
                },
            },
        },


        colors: ["#9966ff", "#63d5be", "#ffd11a",'#ff6150'],
        fill: {
            type: "gradient",
            gradient: {
                shade: "light",
                type: "vertical",
                shadeIntensity: 0.1,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 0.9,
                stops: [0, 100],
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + " Patients";
                },
            },
        },
        responsive: [
            {
                breakpoint: 576,
                options: {
                    chart: {
                        height: 200,
                    },
                },
            },
        ],
    };


    
    useEffect(() => {
        let clientid: string | null;

        if (localStorage.UserType === 'MOS') {
            clientid = selectedClientId;
        } else if (localStorage.UserType === 'Vendor') {
            clientid = selectedClientId;
        } else {
            clientid = localStorage.ClientId;
        }

        
        const fetchData = async () => {
            try {
                const resReceived = await dispatch<any>(getcountresived(clientid));
                const dataArrayReceived: File[] = AesDecrypt(resReceived.payload);
         

                const filterDataByDate = (
                    dataArray: File[],
                    status: string,
                    dateKey: keyof File
                ): number[] => {
                    const selectedMonthValue = selectedMonth?.value ?? 1; // default to January if null
                    const selectedYearValue = selectedYear?.value ?? new Date().getFullYear(); // default to current year

                    // Start from the first day of the selected week (currentWeekStart)
                    const startDay = currentWeekStart.getDate();
                    const startMonth = currentWeekStart.getMonth();
                    const startYear = currentWeekStart.getFullYear();

                    const filteredData = dataArray.filter((file) => {
                        const fileDate = new Date(Date.parse(file[dateKey] as string));

                        // Only include data that falls within the selected month and year
                        return (
                            fileDate.getFullYear() === startYear &&
                            (fileDate.getMonth() === startMonth || fileDate.getMonth() === startMonth + 1) // Handle end of month crossing
                        );
                    });

                    const dailyCounts = Array.from({ length: 7 }, (_, index) => {
                        // Calculate the current date by adding the index to the start date
                        const currentDate = new Date(startYear, startMonth, startDay + index);

                        // Avoid future dates
                        if (currentDate > new Date()) {
                            return 0;
                        }

                        const count = filteredData.filter((file) => {
                            const fileDate = new Date(file[dateKey] as string);

                            return (
                                fileDate.getDate() === currentDate.getDate() &&
                                fileDate.getMonth() === currentDate.getMonth() &&
                                fileDate.getFullYear() === currentDate.getFullYear() &&
                                (status === "completed"
                                    ? file.Data_Fetched === "Completed"
                                    : status === "In progress"
                                        ? file.Data_Fetched === "In progress"
                                        : status === "Error"
                                            ? file.Data_Fetched === "Error"
                                            : true)
                            );
                        }).length;

                        return count;
                    });

                    // Update the state based on status
                    if (status === "In progress") {
                        setinprogressdata(dailyCounts);
                    } else if (status === "Error") {
                        seterrorCount(dailyCounts);
                    } else if (status === "completed") {
                        setCompletedFilesCount(dailyCounts);
                    } else if (status === "received") {
                        setTotalFilesCount(dailyCounts);
                    }

                    return dailyCounts;
                };

                // Apply the filter to the data for each status and date key
                filterDataByDate(dataArrayReceived, "received", "uploaded_date");
                filterDataByDate(dataArrayReceived, "In progress", "uploaded_date");
                filterDataByDate(dataArrayReceived, "completed", "Completed_date");
                filterDataByDate(dataArrayReceived, "Error", "Error_date");
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };


        fetchData();

        const socket = new WebSocket(`${websocket_webservice}ws/Dashboardpatient/`);
        // socket.onopen = () => console.log("WebSocket connected");
        // socket.onclose = () => console.log("WebSocket closed");
        // socket.onerror = (error) => console.error("WebSocket error:", error);

        socket.onmessage = () => {
            fetchData();
        };

        return () => {
            if (socket.readyState === 1) { // <-- This is important
                socket.close();
            }
        }
    }, [dispatch, selectedClientId, currentWeekStart, selectedClient, selectedMonth, selectedYear]);
    useEffect(() => {
        ViewAuditLog();
    }, []);

    const handleSelectClientChange = (selectedOption: SingleValue<IClientOption>) => {
        if (selectedOption) {
            setSelectedClient(selectedOption.label);
            setSelectedClientId(selectedOption.value);
            // template()
        } else {
            setSelectedClient('');
            setSelectedClientId('ALL');
        }
    }

    return (

        <Card className="visitor-card">
            <CardHeader className="card-no-border pb-0">
                <Row>
                    <Col xl={6}>
                        <div className="header-top">
                            <H3 className="m-0">
                                Patients File
                                <span className="f-14 font-primary f-w-600 ms-1">
                                    <SVG iconId="user-visitor" />
                                </span>
                            </H3>
                            <CardHeaderDropDown fItem="Today" sItem="Tomorrow" tItem="Yesterday" mainTitle={true} />
                        </div>
                    </Col>
                    <Col xl={6}>
                        {(localStorage.UserType === 'MOS' || localStorage.UserType === 'Vendor') && (
                            <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                                <Label className="form-label" style={{ fontSize: "110%" }}>
                                    Select Client
                                </Label>
                                <Controller
                                    name="client"
                                    control={control} // control comes from react-hook-form
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            isClearable
                                            options={clientOptions}
                                            classNamePrefix="select"
                                            placeholder="Client Names"
                                            className={classnames("form-control")}
                                            styles={{
                                                control: (styles) => ({
                                                    ...styles,
                                                    width: "100%",
                                                    height: '100%',
                                                    borderColor: "black",
                                                }),
                                                placeholder: (styles) => ({
                                                    ...styles,
                                                    textAlign: "center",
                                                }),
                                            }}
                                            value={selectedClient ? { value: selectedClientId, label: selectedClient } : null}
                                            onChange={(selectedOption) => {
                                                handleSelectClientChange(selectedOption as SingleValue<IClientOption>);
                                                field.onChange(selectedOption); // Update the field's value
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        )}
                    </Col>
                    <div className='d-flex ml-md-auto'>
                        <div className='d-flex align-items-center mt-md-0 mt-1 mr-md-3'>
                            <Col xl={12}>
                                <Label className='form-label' htmlFor='selectedMonth'>
                                    Month
                                </Label>
                                <Select
                                    // theme={selectThemeColors}
                                    options={monthOptions}
                                    value={selectedMonth}
                                    classNamePrefix='select'
                                    className={classNames('react-select', {})}
                                    onChange={(selectedOption) => {
                                        if (selectedOption) {
                                            setSelectedMonth(selectedOption);
                                        } else {
                                            setSelectedMonth(null);
                                        }
                                    }}
                                />
                            </Col>
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div className='d-flex align-items-center mt-md-0 mt-1'>
                            <Col xl={12}>
                                <Label className='form-label' htmlFor='selectedYear'>
                                    Year
                                </Label>
                                <Select
                                    // theme={selectThemeColors}
                                    options={yearOptions}
                                    value={selectedYear}
                                    classNamePrefix='select'
                                    className={classNames('react-select', {})}
                                    onChange={(selectedOption) => {
                                        if (selectedOption) {
                                            setSelectedYear(selectedOption as OptionType);
                                        } else {
                                            setSelectedYear(null);
                                        }
                                    }}
                                />
                            </Col>
                        </div>
                    </div>
                </Row>
            </CardHeader>
            <CardBody className="pt-0">
                <div className="visitors-container">
                    <ReactApexChart id="chart-container" options={monthlyHistoryChart} series={monthlyHistoryChart.series} type="bar" height={270} />
                </div>
                <div className='d-flex align-items-center mt-md-0 mt-1'>

                    <i className="fa fa-arrow-circle-left" id='PreviousWeek' style={{ fontSize: '40px', cursor: 'pointer', color: '#0099ff' }} onClick={() => updateWeek('prev')}>
                        <UncontrolledTooltip placement='top' target='PreviousWeek'>
                            Previous Week
                        </UncontrolledTooltip>
                    </i>
                    <i
                        className="fa fa-arrow-circle-right"
                        id="NextWeek"
                        onClick={isCurrentWeek ? undefined : () => updateWeek('next')} // Prevent click if disabled
                        style={{
                            marginLeft: "90%",
                            fontSize: '40px',
                            cursor: isCurrentWeek ? 'not-allowed' : 'pointer', // Change cursor when disabled
                            color: isCurrentWeek ? '#ccc' : '#0099ff', // Change color when disabled
                        }}
                    >
                        <UncontrolledTooltip placement='top' target='NextWeek'>
                            Next Week
                        </UncontrolledTooltip>
                    </i>

                </div>
            </CardBody>
        </Card>

    );
};

export default Visitors;
