import { Container, Row, Col, Card, CardBody, CardTitle, Button, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import webservice from '../../../Service/webservice';
import { AesEncrypt } from "../../../Service/crypto";
import React, { useEffect, useState, useCallback } from "react";
import classnames from 'classnames';
interface TemplateItem {
  template_data_get_heading?: Record<string, any>;
}


const Template = ({ template_data_get_heading, isOpen }: { template_data_get_heading: any, toggle: () => void, isOpen: boolean }) => {

  return (
    <Card className="equal-height mouse_over-card" style={{ boxShadow: '0 0 10px #26394b' }}>
      <CardHeader style={{ backgroundColor: '#2c4358', color: 'white', cursor: 'pointer' }} >
        <Row>
          <Col xs="10">
            <CardTitle tag="h3" className="card-title">
              <strong>Template Information</strong>
            </CardTitle>
          </Col>
        </Row>
      </CardHeader>
      {isOpen && (
        <CardBody>
          {Object.entries(template_data_get_heading).map(([key, value]) => (
            <div key={key} style={{ marginBottom: '10px' }}>
              <strong>{key.replace(/(^|\s)\S/g, (letter) => letter.toUpperCase())}:</strong>
              <span>{String(value)}</span>
            </div>
          ))}
        </CardBody>
      )}
    </Card>
  );
};

const PatientDetails = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const view: any = queryParams.get('view');
  const [data, setData] = useState<any[]>([]);
  const [TemplateData, setTemplateData] = useState<TemplateItem[]>([]);
  const [expandAll, setExpandAll] = useState(true); // State for button label
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    try {
      const EncParams = AesEncrypt(JSON.stringify({ id: id! }));
      const response = await fetch(webservice + 'api/Patient/GetViewPatientData', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        }
      });
      const result = await response.json();

      if (result === "There is no Record Found") {
        setData([]);
        setTemplateData([]);
      } else {
        setData(result.normal);
        setTemplateData(result.Template);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [id]);

  useEffect(() => {
    fetchData();

  }, [fetchData]);

  const [isAllOpen, setIsAllOpen] = useState(true);
  const toggleAllSections = () => {
    setIsAllOpen(prevState => !prevState);
  };
  const JsonViewer: React.FC<{ data: any }> = ({ data }) => {
    // State to track if all sections are open

    if (typeof data !== 'object' || data === null) {
      return <span>{String(data)}</span>;
    }



    return (
      <div className="json-viewer">

        {Object.entries(data).map(([key, value], index) => (
          <JsonNode key={index} nodeKey={key} value={value} isOpen={isAllOpen} />
        ))}
      </div>
    );
  };

  const JsonNode: React.FC<{ nodeKey: string; value: any; isOpen: boolean }> = ({ nodeKey, value, isOpen }) => {
    const [isLocalOpen, setIsLocalOpen] = useState(isOpen); // Local state for individual node
    const isObject = typeof value === 'object' && value !== null;

    const toggleLocalNode = () => {
      setIsLocalOpen(prev => !prev);
    };

    React.useEffect(() => {
      setIsLocalOpen(isOpen);
    }, [isOpen]);

    return (
      <div className="json-node">
        <div className="json-card">
          <div
            className="json-card-header"
            onClick={toggleLocalNode} // Toggle individual node
          >
            <span className="json-card-title">
              {nodeKey.toUpperCase()}:
            </span>
            {isObject && (
              <span style={{ marginLeft: '8px', cursor: 'pointer' }}>
                {isLocalOpen ? '−' : '+'} {/* Plus/Minus Icon */}
              </span>
            )}
          </div>
          {isLocalOpen && isObject && (  // Use local state to control visibility
            <div className="json-card-body">
              <JsonViewer data={value} />
            </div>
          )}
          {!isObject && <span className="json-value">{String(value)}</span>}
        </div>
      </div>
    );
  };


  const handleback = async () => {
    if (view === 'ManualPatientView') {
      navigate(`/BulkUpload?view=manual`);
    } else if (view === 'PatientsView') {
      navigate(`/patients`);
    } else {
      navigate(view);
    }
  };

  const [activeTab, setActiveTab] = useState('1');

  const toggleTab = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <Breadcrumbs mainTitle='Patient Eligibility Info' parent="Patient" title="Patient Eligibility Info" to={`/patients`} />
      <Container fluid>
        <Row>
          <Col xs="2">
            <Button color="primary" onClick={handleback} className="ml-2">
              Back
            </Button>
          </Col>
          <Col xs="4">
            <Nav tabs className="custom-tabs">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' }, 'custom-tab')}
                  onClick={() => { toggleTab('1'); }}
                >
                  API View
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' }, 'custom-tab')}
                  onClick={() => { toggleTab('2'); }}
                >
                  Template View
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col xs="2">
            <div className="d-flex justify-content-end mb-3">
              <Button color="primary" onClick={toggleAllSections}>
                {expandAll ? 'Collapse All' : 'Expand All'}
              </Button>
            </div>
          </Col>
        </Row>
        <TabContent activeTab={activeTab} className="w-100">
          <TabPane tabId="1">
            <br />
            <Row>
              <JsonViewer data={data as any} />
            </Row>

          </TabPane>
          <TabPane tabId="2">
            <br />
            <Row>
              <Col sm="12" md="6" lg="12">
                <Template
                  template_data_get_heading={TemplateData}
                  isOpen={true}
                  toggle={() => { }}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Container>
    </>
  );
};

export default PatientDetails;
