import { useState, useEffect, Fragment } from 'react';
import { Card, CardBody, Row, Col, Button, Table, Label } from 'reactstrap';
import classnames from 'classnames';
import Select, { SingleValue } from 'react-select';
import { Search } from 'react-feather';
import { useDispatch } from 'react-redux';
import { AesEncrypt, AesDecrypt } from '../../../Service/crypto';
import webservice from '../../../Service/webservice';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs';
import moment from 'moment';

type AuditData = {
  Modified: string;
  Action: string;
  JsonData: any;
  UserName: string;
};

type OptionType = {
  value: string;
  label: string;
};

const Action: OptionType[] = [
  { value: 'ADD', label: 'ADD' },
  { value: 'EDIT', label: 'EDIT' },
  { value: 'DELETE', label: 'DELETE' },
  { value: 'VIEW', label: 'VIEW' },
  { value: 'DOWNLOAD', label: 'DOWNLOAD' },
  { value: 'CLIENT MAPPING', label: 'CLIENT MAPPING' },
  { value: 'FILE ALLOCATE', label: 'FILE ALLOCATE' },
];

const ReportAudit = () => {
  const dispatch = useDispatch();
  const currentDate = new Date();
  const oneWeekBefore = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  const [oneWeekBeforePicker, setOneWeekBeforePicker] = useState<Date | null>(oneWeekBefore);
  const [currentDatePicker, setCurrentDatePicker] = useState<Date | null>(currentDate);
  const [submit, setSubmit] = useState(false);
  const [auditData, setAuditData] = useState<OptionType[]>([]);
  const [auditData1, setAuditData1] = useState<AuditData[]>([]);
  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const [selectedAudit, setSelectedAudit] = useState<string | null>(null);
  const [scrollInnerCard, setScrollInnerCard] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [auditSelectError, setAuditSelectError] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');

  const getAuditlogdata = async (data: any): Promise<AuditData[]> => {
    const id = localStorage.id;
    const UserType = localStorage.UserType;
    const Data = { id, UserType, data }; // Assuming data is already defined somewhere
    const EncParams = AesEncrypt(JSON.stringify(Data));
    const res = await fetch(webservice + 'api/AuditReport/getAuditLog', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then((response) => response.json());

    const TempData = AesDecrypt(res);

    
    TempData.forEach((element: any) => {
      const utcDate = moment.utc(element.Modified);
      const localDate = utcDate.local().format('MM/DD/YYYY hh:mm:ss A');
      element.Modified = localDate;
    });
    return TempData;
  };

  const spinnerStyle: React.CSSProperties = {
    position: 'fixed',
    top: '60%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
    borderRadius: '8px',
    padding: '16px',
  };

  const handleSubmit = async () => {
    setShowResults(true);
    setLoading(true);

    if (!selectedAudit) {
      setAuditSelectError('Please select an audit type');
      setLoading(false);
      return;
    }

    if (!oneWeekBeforePicker) {
      setStartDateError('Please select a start date');
      setLoading(false);
      return;
    }

    if (!currentDatePicker) {
      setEndDateError('Please select an end date');
      setLoading(false);
      return;
    }

    setAuditSelectError('');
    setStartDateError('');
    setEndDateError('');

    const data = {
      table: selectedAudit,
      fromDate: oneWeekBeforePicker,
      toDate: currentDatePicker,
      action: selectedAction,
    };
    setAuditSelectError('');
    const res = await getAuditlogdata(data);
    setAuditData1(res);
    setLoading(false);
  };

  const ReportAuditlog = async () => {
    const response = await fetch(webservice + 'api/AuditReport/reportAuditLog', {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    const responseData = await response.json();
    const TempData = AesDecrypt(responseData);

    return TempData;
  };

  useEffect(() => {
    const fetchAuditData = async () => {
      try {
        const res1 = await ReportAuditlog();
        const options = res1.map((item: string) => ({
          value: item,
          label: item
            .replace('Log_', ' ')
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase())
            .replace('Usertype', 'User Type')
            .replace('Usermanagement', 'User Management'),
        }));
        setAuditData(options);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAuditData();
  }, []);

  const tableHeaderStyle: React.CSSProperties = {
    width: '-10px',
    textAlign: 'center',
    color: '#f2f2f2',
    backgroundColor: 'rgb(96, 125, 145)',
    padding: '5px',
    fontWeight: 'bold',
    borderBottom: '1px solid #7a6362',
    borderTop: '1px solid #7a6362',
    borderLeft: '1px solid #7a6362',
    borderRight: '1px solid #7a6362',
  };

  const tableHeaderStyleIN: React.CSSProperties = {
    textAlign: 'center',
    color: '#f2f2f2',
    backgroundColor: 'rgb(96, 125, 145)',
    padding: '5px',
    width: '50%',
    fontWeight: 'bold',
    borderBottom: '1px solid #7a6362',
    borderTop: '1px solid #7a6362',
    borderLeft: '1px solid #7a6362',
    borderRight: '1px solid #7a6362',
  };

  const tableDataStyle: React.CSSProperties = {
    textAlign: 'left',
    color: '#232323',
    backgroundColor: '#f2f2f2',
    padding: '10px',
    borderBottom: '1px solid #7a6362',
    borderTop: '1px solid #7a6362',
    borderLeft: '1px solid #7a6362',
    borderRight: '1px solid #7a6362',
  };

  const tableHeaderStyleA: React.CSSProperties = {
    textAlign: 'center',
    color: '#f2f2f2',
    backgroundColor: 'rgb(96, 125, 145)',
    padding: '5px',
    fontWeight: 'bold',
    borderBottom: '1px solid #7a6362',
    borderTop: '1px solid #7a6362',
    borderLeft: '1px solid #7a6362',
    borderRight: '1px solid #7a6362',
  };

  const tableDataStyleA: React.CSSProperties = {
    textAlign: 'left',
    color: '#232323',
    backgroundColor: '#f2f2f2',
    padding: '10px',
    borderBottom: '1px solid #7a6362',
    borderTop: '1px solid #7a6362',
    borderLeft: '1px solid #7a6362',
    borderRight: '1px solid #7a6362',
  };

  const renderJsonDataTable = (jsonData: any, action: string) => {
    const renderProvidersTable = (providers: any[]) => (
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <th className="audit-tableHeaderStyle">First Name</th>
            <th className="audit-tableHeaderStyle">Middle Name</th>
            <th className="audit-tableHeaderStyle">Last Name</th>
            <th className="audit-tableHeaderStyle">Provider ID</th>
          </tr>
        </thead>
        <tbody>
          {providers.map((provider: any, idx: number) => (
            <tr key={idx}>
              <td className="audit-tableDataStyle">{provider.firstName}</td>
              <td className="audit-tableDataStyle">{provider.middleName}</td>
              <td className="audit-tableDataStyle">{provider.lastName}</td>
              <td className="audit-tableDataStyle">{provider.providerId}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  
    if (action === 'VIEW') {
      return <div>{jsonData}</div>;
    }
    if (action === 'Regenrate') {
      return <div>{jsonData}</div>;
    }
    if (action === 'DELETE') {
      return <div>{jsonData}</div>;
    }
    if (action === 'DOWNLOAD') {
      return <div>{jsonData}</div>;
    }
  
    if (action === 'EDIT') {
      if (Array.isArray(jsonData)) {
        return (
          <Table bordered size="sm">
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Field</th>
                <th style={tableHeaderStyle}>Before</th>
                <th style={tableHeaderStyle}>After</th>
              </tr>
            </thead>
            <tbody>
              {jsonData.map(
                (
                  item: { field: string; value: any; before: string | any[]; after: string | any[] },
                  index: number
                ) => (
                  <tr key={index}>
                    <td style={tableDataStyleA}>
                      <strong>{item.field}</strong>
                    </td>
                    <td style={tableDataStyleA}>
                      {item.field === 'Permission' && Array.isArray(item.before) ? (
                        <table style={{ width: '100%' }}>
                          <thead>
                            <tr>
                              <th className="audit-tableHeaderStyle">Menu</th>
                              <th className="audit-tableHeaderStyle">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.before.map((permission: any) => (
                              <tr key={permission.MenuLabel}>
                                <td className="audit-tableDataStyle">{permission.MenuLabel}</td>
                                <td className="audit-tableDataStyle">
                                  {Array.isArray(permission.MenuActions) ? permission.MenuActions.join(', ') : permission.MenuActions}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : item.field === 'PayorUsernames' && Array.isArray(item.before) ? (
                        item.before.join(', ')
                      ) : item.field === 'Providers' && Array.isArray(item.before) ? (
                        renderProvidersTable(item.before)
                      ) : (
                        item.before
                      )}
                    </td>
                    <td style={tableDataStyleA}>
                      {item.field === 'Permission' && Array.isArray(item.after) ? (
                        <table style={{ width: '100%' }}>
                          <thead>
                            <tr>
                              <th className="audit-tableHeaderStyle">Menu</th>
                              <th className="audit-tableHeaderStyle">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.after.map((permission: any) => (
                              <tr key={permission.MenuLabel}>
                                <td className="audit-tableDataStyle">{permission.MenuLabel}</td>
                                <td className="audit-tableDataStyle">
                                  {Array.isArray(permission.MenuActions) ? permission.MenuActions.join(', ') : permission.MenuActions}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : item.field === 'PayorUsernames' && Array.isArray(item.after) ? (
                        item.after.join(', ')
                      ) : item.field === 'Providers' && Array.isArray(item.after) ? (
                        renderProvidersTable(item.after)
                      ) : (
                        item.after
                      )}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        );
      } else if (typeof jsonData === 'object') {
        return (
          <Table bordered size="sm">
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Field</th>
                <th style={tableHeaderStyle}>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(jsonData).map(([key, value], index) => (
                <tr key={index}>
                  <td style={tableDataStyleA}><strong>{key}</strong></td>
                  <td style={tableDataStyleA}>
                    {Array.isArray(value) ? value.join(', ') : String(value)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        );
      } else {
        return <div><center>No data available</center></div>;
      }
    }
  
    if (action === 'ADD') {
      if (Array.isArray(jsonData)) {
        return (
          <Table bordered size="sm">
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Field</th>
                <th style={tableHeaderStyle}>Value</th>
              </tr>
            </thead>
            <tbody>
              {jsonData.map((item: { field: string; value: any }, index: number) => (
                <tr key={index}>
                  <td style={tableDataStyleA}><strong>{item.field}</strong></td>
                  <td className="audit-tableDataStyle">
                    {item.field === 'Permission' && Array.isArray(item.value) ? (
                      <table style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th className="audit-tableHeaderStyle">Menu</th>
                            <th className="audit-tableHeaderStyle">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.value.map((permission: any) => (
                            <tr key={permission.MenuLabel}>
                              <td className="audit-tableDataStyle">{permission.MenuLabel}</td>
                              <td className="audit-tableDataStyle">{permission.MenuActions}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : item.field === 'PayorUsernames' && Array.isArray(item.value) ? (
                      item.value.join(', ')
                    ) : item.field === 'Password' || item.field === 'Pin' ? (
                      '******'
                    ) : item.field === 'Providers' && Array.isArray(item.value) ? (
                      renderProvidersTable(item.value)
                    ) : (
                      typeof item.value === 'object' ? (
                        Object.entries(item.value).map(([key, val]) => (
                          <div key={key}>{`${key}: ${val}`}</div>
                        ))
                      ) : (
                        item.value
                      )
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        );
      } else {
        return <div><center>No data available</center></div>;
      }
    }
    
    if (Array.isArray(jsonData)) {
      return (
        <Table bordered size="sm">
          <thead>
            <tr>
              <th style={tableHeaderStyleIN}>Field</th>
              <th style={tableHeaderStyleIN}>Value</th>
            </tr>
          </thead>
          <tbody>
            {jsonData.map((item: { field: string; value: any }, index: number) => (
              <tr key={index}>
                <td style={tableDataStyleA}><strong>{item.field}</strong></td>
                <td style={tableDataStyleA}>
                  {item.field === 'Password' || item.field === 'pin' ? (
                    '******'
                  ) : Array.isArray(item.value) ? (
                    item.value.map((val: any, idx: number) => (
                      <div key={idx}>{val}</div>
                    ))
                  ) : item.field === 'Providers' && Array.isArray(item.value) ? (
                    renderProvidersTable(item.value)
                  ) : (
                    typeof item.value === 'object' ? (
                      Object.entries(item.value).map(([key, val]) => (
                        <div key={key}>{`${key}: ${val}`}</div>
                      ))
                    ) : (
                      item.value
                    )
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    } else {
      return <div><center>No data available</center></div>;
    }
  }
  
  
  return (
    <Fragment>
      {loading && (
        <div style={spinnerStyle}>
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      <Breadcrumbs mainTitle='Report Audit' title='Report Audit' />
      <Card className={classnames('card-action', { 'card-scroll': scrollInnerCard })}>
        <CardBody>
          <Row>
            <Col md={3} className="mb-1">
              <Label for="audit-select">Audit Select</Label>
              <Select
                id="audit-select"
                className="react-select"
                classNamePrefix="select"
                isClearable
                options={auditData}
                value={auditData.find((option) => option.value === selectedAudit)}
                onChange={(option: SingleValue<OptionType>) => setSelectedAudit(option?.value || null)}
              />
              {auditSelectError && <div className="text-danger">{auditSelectError}</div>}
            </Col>
            <Col md={3} className="mb-1">
              <Label for="start-date-picker" >Start Date</Label>
              <br />
              <ReactDatePicker
                id="start-date-picker"
                className="form-control"
                selected={oneWeekBeforePicker}
                onChange={(date: Date | null) => setOneWeekBeforePicker(date)}
                dateFormat="yyyy-MM-dd"
                isClearable
                
              />
              {startDateError && <div className="text-danger">{startDateError}</div>}
            </Col>
            <Col md={3} className="mb-1">
              <Label for="end-date-picker">End Date</Label>
              <br />
              <ReactDatePicker
                id="end-date-picker"
                className="form-control"
                selected={currentDatePicker}
                onChange={(date: Date | null) => setCurrentDatePicker(date)}
                dateFormat="yyyy-MM-dd"
                isClearable
              />
              {endDateError && <div className="text-danger">{endDateError}</div>}
            </Col>
            <Col md={3} >
              <Label for="action-select">Action</Label>
              <Select
                id="action-select"
                className="react-select"
                classNamePrefix="select"
                isClearable
                options={Action}
                value={Action.find((option) => option.value === selectedAction)}
                onChange={(option: SingleValue<OptionType>) => setSelectedAction(option?.value || null)}
              />
            </Col>
            <Col xs={12} className='text-center mt-2 pt-50'>
              <div
                className={classnames('customizer', {
                  open: submit,
                })}
              >
                <button
                  onClick={() => {
                    setScrollInnerCard(true);
                    if (!selectedAudit) {
                      setAuditSelectError('Please select Table Name');
                      return;
                    }
                    handleSubmit();
                  }}
                  className='rounded-circle customizer-toggle d-flex align-items-center justify-content-center'
                >
                  <Search size={14} />
                </button>
              </div>
            </Col>
          </Row>
          {showResults && (
            <div className="result-container">
              {auditData1.length > 0 ? (
                <Table bordered>
                  <thead>
                    <tr>
                      <th style={tableHeaderStyleA}>UserName</th>
                      <th style={tableHeaderStyleA}>Modified</th>        
                      <th style={tableHeaderStyleA}>Data</th>
                      <th style={tableHeaderStyleA}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {auditData1.map((data, index) => (
                      <tr key={index}>
                        <td style={tableDataStyle}>{data.UserName}</td>
                        <td style={tableDataStyle}>{data.Modified}</td>    
                        <td style={tableDataStyle}>{renderJsonDataTable(data.JsonData, data.Action)}</td>
                        <td style={tableDataStyle}>{data.Action}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div><center>No data available</center></div>
              )}
            </div>
          )}
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default ReportAudit;
