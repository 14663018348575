import { Col, Container, Row } from "reactstrap";
import {Dashboard, WeAreComingSoon} from "../../../utils/Constant";
// import Visitors from "./Filecount/Filecount";
import ClientwiseError from "./ClientwiseError";
import Visitors from "./Filecount/Filecount";
import OrderStatus from "./Progreesbar/Progressbar";
import Apistatus from './Piechart/Apitriggercount'
import TAT from "./Turnaroundtime/Tatchart";
import Taterror from './Turnaroundtime/Taterror'
import Filetimetaken from "./Filetime/Filetime";
import { H2, H5 } from "../../../AbstractElements";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import VendorLog from "./VendorLog";

const ShoppingPlace = () => {
  return (
    <Container fluid className="dashboard_default">
      <Row className="widget-grid">
        <Breadcrumbs mainTitle={Dashboard} parent={Dashboard} title={Dashboard} />
          <Col xl={6}>
            <Visitors />
          </Col>
          <Col xl={6}>
            <ClientwiseError />
          </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <OrderStatus />
        </Col>
        <Col xl={6}>
          {/* <Apistatus /> */}
          <Filetimetaken/>
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <TAT />
        </Col>
        <Col xl={6}>
          <Taterror />
        </Col>
      </Row>
      <Row>
        <Col xl={12}>
        <VendorLog />
        </Col>
      </Row>
    </Container>
  );
};

export default ShoppingPlace;
